import { gql } from 'graphql-tag'

export const UPDATE_CUSTOMIZATIONS_MUTATION = gql`
  mutation updateCustomizations($customizations: [CustomizationDto!]!) {
    updateCustomizations(customizations: $customizations) {
      field
      value
    }
  }
`
