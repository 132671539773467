<template>
  <TransitionGroup
    class="container"
    tag="div"
    name="list"
  >
    <div
      v-if="isEditable"
      key="header"
      class="container__edit-header"
    >
      <div class="edit-header__changed">
        <span>
          {{
            $t('settings.workspace.preferences.updatedPreferences', {
              count: updatedCustomizationFields.length
            })
          }}
        </span>
      </div>
      <div class="edit-header__actions">
        <span
          class="actions__cancel"
          @click="cancelEdition"
        >
          {{ $t('settings.usersPermissions.dataSensitivity.table.cancel') }}
        </span>
        <ReflectButton
          class="edit-header__save-button"
          :disabled="!hasUnsavedModification"
          @click="saveCustomizations"
        >
          {{ $t('settings.workspace.preferences.savePreferences') }}
        </ReflectButton>
      </div>
    </div>

    <div
      v-if="editableCustomizations"
      key="table"
      class="container__module"
    >
      <ReflectTable
        :columns="columns"
        :with-vertical-borders="false"
        :without-full-height-scroll="true"
        :with-y-overflow="true"
      >
        <tr
          v-for="customization in initialCustomizations"
          :key="customization.field"
        >
          <td class="table__cell">
            {{ getCustomizationFieldTranslation(customization.field) }}
          </td>
          <td class="table__cell table__cell--second">
            {{ $t(CUSTOMIZATION_FIELDS_DEFINITION_KEY[customization.field]) }}
          </td>
          <td class="table__cell table__cell--last">
            <ReflectSelectField
              :options="getOptionsTranslation(customization)"
              :close-on-select="true"
              :has-labeled-options="true"
              track-by="value"
              :disabled="!isEditable"
              open-direction="bottom"
              :value="getSelectedCustomizationOption(customization)"
              with-small-text
              :searchable="false"
              @input="
                ({ value }) =>
                  setSelectedCustomizationOption(customization.field, value)
              "
            />
          </td>
        </tr>
      </ReflectTable>
    </div>
  </TransitionGroup>
</template>

<script lang="ts">
import { useToast } from 'vue-toastification'
import { useRouterStore } from '~/stores/router'
import { getCustomizationFieldTranslation } from '~/services/explore'
import {
  type Customizations,
  type CustomizationField,
  type CustomizationFieldWithOptions
} from '~/types/customization'
import { useCustomizationStore } from '~/stores/customization'
import { UPDATE_CUSTOMIZATIONS_MUTATION } from '~/gql/customizations/updateCustomizations'
import { CUSTOMIZATION_FIELDS_DEFINITION_KEY } from '~/constants/customization'
import { formatCustomizationOptionLabel } from '~/services/format'

const CUSTOMIZATIONS_TABLE_COLUMNS = {
  FIELD: 'field',
  DESCRIPTION: 'description',
  VALUE: 'value'
} as const

export default {
  name: 'CustomizationsEditor',
  props: {
    isEditable: {
      type: Boolean,
      default: false
    },
    initialCustomizations: {
      type: Array as PropType<CustomizationFieldWithOptions[]>,
      required: true
    }
  },
  emits: ['cancel', 'updated'],
  setup() {
    return {
      getCustomizationFieldTranslation,
      CUSTOMIZATION_FIELDS_DEFINITION_KEY
    }
  },
  data(): {
    editableCustomizations: Customizations | null
  } {
    return {
      editableCustomizations: null
    }
  },
  computed: {
    columns() {
      return [
        {
          key: CUSTOMIZATIONS_TABLE_COLUMNS.FIELD,
          value: this.$t('settings.workspace.preferences.table.fieldName')
        },
        {
          key: CUSTOMIZATIONS_TABLE_COLUMNS.DESCRIPTION,
          value: this.$t('settings.workspace.preferences.table.description')
        },
        {
          key: CUSTOMIZATIONS_TABLE_COLUMNS.VALUE,
          value: this.$t('settings.workspace.preferences.table.customizedValue')
        }
      ]
    },
    hasUnsavedModification() {
      return this.updatedCustomizationFields.length > 0
    },
    updatedCustomizationFields() {
      return this.editableCustomizations
        ? (
            Object.keys(this.editableCustomizations) as CustomizationField[]
          ).filter(
            field =>
              this.editableCustomizations![field] !==
              useCustomizationStore().customizations![field]
          )
        : []
    }
  },
  watch: {
    hasUnsavedModification: {
      handler(newValue) {
        const { setHasUnsavedModification } = useRouterStore()
        if (newValue) {
          setHasUnsavedModification(true)
        } else {
          setHasUnsavedModification(false)
        }
      }
    }
  },
  created() {
    this.initiateEditableCustomizations()
  },
  methods: {
    cancelEdition() {
      this.$emit('cancel')
      this.initiateEditableCustomizations()
    },
    async saveCustomizations() {
      const customizationsToUpdate = this.updatedCustomizationFields.map(
        field => ({
          field,
          value: this.editableCustomizations![field]
        })
      )

      try {
        const {
          data: { updateCustomizations }
        } = await this.$apiGqlClient.mutate({
          mutation: UPDATE_CUSTOMIZATIONS_MUTATION,
          variables: { customizations: customizationsToUpdate }
        })

        if (updateCustomizations) {
          const toast = useToast()
          toast.success(this.$t('settings.toaster.success'))
          this.$emit('updated', this.editableCustomizations)
        }
      } catch (error) {
        const toast = useToast()
        toast.error(this.$t('settings.toaster.error'))
        throw error
      }
    },
    setSelectedCustomizationOption(field: CustomizationField, value: string) {
      this.editableCustomizations![field] = value
    },
    initiateEditableCustomizations() {
      this.editableCustomizations = this.initialCustomizations.reduce(
        (acc, customization) => ({
          ...acc,
          [customization.field]: customization.value
        }),
        {}
      ) as Customizations
    },
    getOptionsTranslation(customization: CustomizationFieldWithOptions) {
      return customization.options.map(option => ({
        label: formatCustomizationOptionLabel(customization, option),
        value: option
      }))
    },
    getSelectedCustomizationOption(
      customization: CustomizationFieldWithOptions
    ) {
      return {
        value: this.editableCustomizations![customization.field],
        label: formatCustomizationOptionLabel(
          customization,
          this.editableCustomizations![customization.field]
        )
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  position: relative;
  display: flex;
  flex-direction: column;

  &__edit-header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: $margin-regular 0;

    .edit-header {
      &__changed {
        display: flex;
        flex-direction: column;
        margin-right: $margin-small;
        color: $text-primary;
        text-align: right;
        gap: 2px;
        @include font-text;
        @include font-size($font-size-mini);
      }

      &__actions {
        display: flex;
        align-items: center;
        gap: $margin-small;
        @include font-text;
        @include font-size($font-size-regular);

        .actions {
          &__cancel {
            cursor: pointer;
          }

          &__cancel:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }

  &__module {
    box-sizing: border-box;
    height: 100%;
    background-color: $bg-secondary;
    border-radius: 8px;
    @include block-shadow;

    .table {
      &__cell {
        @include font-size($font-size-mini);
        height: 32px;
        box-sizing: content-box;
        padding: $margin-mini;
        padding-left: $margin-regular;
        white-space: nowrap;
        @include font-text;
        vertical-align: middle;
        overflow-x: hidden;
        text-overflow: ellipsis;
        text-align: left;
        color: $text-primary;

        &--second {
          white-space: initial;
          color: $text-tertiary;
          margin: auto;
        }

        &--last {
          padding-right: $margin-small;
          overflow: visible;
        }
      }
    }
  }
}
</style>
